@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.footer {

  .backgroundImage {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .footerContainer {
    font-size: 14px;
    padding: 30px 15px 0px 15px;
    color: #999;
    position: relative;
    background-color: #333333;
    @media (--viewportLarge) {
      padding: 30px 15px;
    }
    @media (max-width: 550px) {
      .container {
        max-width: none;

      }
    }


    .links {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      @media (--viewportLarge) {}

      .linksFull {
        width: 100%;

        @media (--viewportLarge) {
          flex: auto;
          width: auto;
        }
      }

      .linksFlex {
        flex: 1;

        @media (--viewportLarge) {
          flex: auto;
        }
      }
      .linksFlexService {
        flex: 1;
        display: none;

        @media (--viewportLarge) {
          flex: auto;
          display: block;
        }
      }

      .footerLogo {
        margin: 0 auto;
        display: block;
        max-width: 100%;

        @media (--viewportSmall) {
          margin: 0;
        }
      }

      .social {
        display: flex;
        gap: 20px;
        margin: 30px 0;
        justify-content: center;

        @media (--viewportSmall) {
          margin: 30px;
          justify-content: unset;
        }
      }

      h5 {
        font-size: 14px;
        font-weight: var(--fontWeightBold);
        margin: 0 0 15px 0;

        @media (--viewportSmall) {
          font-size: 16px;
        }
      }

      ul {
        margin: 0 0 30px 0;
      }

      a {
        font-size: 14px;
        color: #999999;
      }

      .iconLink {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 45px;
        color: #999999;
        img{
          max-width: 30px;
          @media (--viewportMedium) {
            max-width: 40px;
          }
        }

        h5 {
          margin: 0;
        }

        a {
          color: #999999;
        }
      }
    }

    .copy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column-reverse;
      gap: 10px;
     
      color: #999999;
      padding-top: 20px;
      padding-bottom: 0px;
      font-weight: var(--fontWeightRegular);
      border-top: solid 1px rgba(255, 255, 255, 0.1);

      @media (--viewportMedium) {
        padding-bottom: 0;
        padding-top: 30px;
        padding-bottom: 50px;
        flex-direction: column;
      }

      @media (--viewportLarge) {
        flex-direction: row;
      }

      &>div:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (--viewportSmall) {
          display: block;
        }

      }

      a {
        color: #999999;
        margin: 0 15px;
      }
    }

    .goTop {
      text-align: center;
      padding-top: 20px;

      @media (--viewportSmall) {
        position: absolute;
        right: 30px;
        bottom: 85px;
        padding-top: 0;
      }
    }

  }
}

.footerRightColumn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  @media (--viewportMedium) {
    flex: 3 1;
  }
}