@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.allEvents {
  padding: 15px 0;
  z-index: 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 50px 0 30px 0;
  }

  .itemEvent {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 45px;
      margin: 0 auto;
    }

    span {
      color: var(--colorGrey);
      font-size: 12px;
      line-height: 1.3em;
      text-align: center;
    }

    @media only screen and (max-width: 550px) {
      .itemImg {
        width: 60px;
        height: 60px;
        background: #ddd;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;

        img {
          max-width: 30px;
          filter: brightness(0) invert(1);
        }

      }
    }
  }
}

.service {
  padding: 30px 0;

  @media (--viewportSmall) {
    background-color: #faf2f4;
    padding: 75px 0;
  }

  .title {
    margin: 0 auto;
    padding: 0 15px;

    @media (--viewportSmall) {
      max-width: 60%;
      text-align: center;
    }

    img {
      width: 70px;

      @media (--viewportSmall) {
        width: auto;
      }
    }

    h3 {
      text-transform: uppercase;
      position: relative;
      margin: 10px 0;
      font-size: 24px;

      @media (--viewportSmall) {
        font-size: 36px;
      }

      @media (--viewportLarge) {
        font-size: 36px;
        margin: 0 0 20px 0;
      }
    }

    .highlight {
      @media (--viewportLarge) {
        margin-top: -40px;
      }
    }

    p {
      margin: 0;
    }

    .VisibleMobile {
      h4 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: var(--fontWeightSemiBold);
      }

      p {
        font-weight: var(--fontWeightRegular);
        color: var(--colorGrey);
      }

      .serveiceTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .btnLink {
          border: none;
          padding: 0;
        }
      }
    }
  }

  .action {
    display: none;
    justify-content: center;
    padding-top: 20px;

    @media (--viewportSmall) {
      padding-top: 50px;
      display: flex;
    }
  }
}
.eventContainer {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap if needed */
  justify-content: space-around; /* Space out items evenly */
}