@import '../../styles/customMediaQueries.css';
.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileToolbar{
  background: #fff;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--boxShadowFilterButton);
  
  @media (--viewportMedium) {
    display: none;
  }
  a{
    display: block;
    padding: 10px;
  }
  img{
    max-width: 30px;
    width: 100%;
  }
  .middleToolbar{
    background: var(--bgBrandGradient);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    img{
      filter: brightness(0) invert(1);
    }
  }
  
}