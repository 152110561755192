@import '../../../../styles/custom.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;

    .drawerBtn {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        padding: 0;
        display: flex;
        gap: 0;
        font-size: 27px;
        border-color: #f0f0f0;

        svg {
            margin: 0 -9px;
        }

        &:hover {
            background-color: #f1f1f1 !important;
            border-color: #f1f1f1 !important;
            color: rgba(0, 0, 0, 0.88) !important;
        }
    }

    .headerLeft {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .headerRight {
        ul {
            list-style: none;
            display: flex;
            padding: 0;
            margin: 0;

            li {
                padding: 0;

                a {
                    color: black;
                    font-size: 16px;
                    padding: 25px 20px;
                    display: inline-flex;
                    align-items: center;
                    gap: 10px;

                    &:hover {
                        color: var(--colorBrand);
                        text-decoration: none;
                    }

                    .menudrop {
                        font-size: 10px;
                    }
                }
            }
        }
    }

}