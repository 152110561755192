@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.location {

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    padding-left: calc(50vw - var(--desktopWidth)/2);
  }

  .content {
    padding: 15px;
    border-top: dashed 1px #ccc;

    @media (--viewportSmall) {
      padding: 15px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      border-top: none;
      text-align: left;
    }

    h2 {
      padding: 0;
      color: #000;
      line-height: 1em;
      z-index: 2;
      margin: 0;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: var(--fontWeightSemiBold);
      text-align: left;

      @media (--viewportSmall) {
        font-size: 36px;
        font-weight: var(--fontWeightBold);
        text-transform: none;
        margin: 0 0 20px 0;
      }

      @media (--viewportLarge) {
        font-size: 4vmax;
        width: 170%;
        margin: 0 0 45px 0;
        mix-blend-mode: exclusion;
        color: #c6c6c6;
      }
    }

    p {
      display: none;

      @media (--viewportSmall) {
        display: block;
      }
    }

    .brandButton {
      display: none;

      @media (--viewportSmall) {
        display: block;
      }

    }
  }

  .serveiceTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (--viewportSmall) {
      display: none;
    }

    .btnLink {
      border: none;
      padding: 0;
    }
  }

  .locMobile {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;

    div {
      border-radius: 4px;
      background: #cccccc;
      text-align: center;
      position: relative;
      padding: 3px;

      img {
        max-width: 100%;
        border-radius: 4px;
      }

      h5 {
        color: #ffffff;
        margin-top: 5px;
      }

      span {
        display: block;
        position: absolute;
        width: 42px;
        height: 25px;
        border-radius: 16px;
        background: #ffffff3b;
        color: #fff;
        line-height: 25px;
        text-align: center;
        border: solid 1px #fff;
        bottom: 34px;
        right: 5px;
        backdrop-filter: saturate(180%) blur(20px);
      }
    }

    a:nth-child(1) div {
      background-color: #d84452;
    }

    a:nth-child(2) div {
      background-color: #e7553e;
    }

    a:nth-child(3) div {
      background-color: #fdb747;
    }

    a:nth-child(4) div {
      background-color: #88c053;
    }

    a:nth-child(5) div {
      background-color: #35ba9b;
    }

    a:nth-child(6) div {
      background-color: #3aadd9;
    }

    a:nth-child(7) div {
      background-color: #4a89d9;
    }

    a:nth-child(8) div {
      background-color: #9479da;
    }

    a:nth-child(9) div {
      background-color: #d46fab;
    }

    a:nth-child(10) div {
      background-color: #e2b691;
    }
  }

  .slider {
    background: #2a2e43;
    position: relative;
    padding: 15px 0;
    overflow: hidden;
    width: 100vw;
    display: none;

    @media (--viewportSmall) {
      flex: 1 1 auto !important;
      padding: 70px 0 180px 75px;
      display: flex;
      justify-content: flex-end;
      width: calc(50vw - var(--desktopWidth)/2);
      display: block;
    }

    .locBg {
      position: absolute;
      object-fit: cover;
      left: 0;
      top: 0;
      width: 100%;
    }
  }


  .locCardHideMobile {
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }
}