@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.how {
  padding: 15px;
  @media (--viewportLarge) {
    padding: 50px 0;
  }
  @media (max-width: 550px) {
    .container{
      max-width: none;

    }
  }

  .howContainer {
    display: flex;
    flex-direction: column-reverse;
    @media (--viewportMedium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }

    .videoContainer {
      position: relative;
      padding-left: 33px;

      .image {
        max-width: 100%;
      }

      .videoBg {
        position: absolute;
        top: 10px;
        left: 0;
        background: #fff;
        border-radius: 10px;
        padding: 8px 8px 30px 8px;
        box-shadow: var(--boxShadowSectionLocationHover);
        @media (--viewportSmall) {   
          top: 70px;
        }

        .nav {
          margin-bottom: 8px;
        }

        .video {
          img {
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }

    .textContainer {
      text-align: center;
      padding: 30px 0;
      @media (--viewportLarge) {
        padding: 0 100px;
        text-align: left;
      }

      .textRight {
        text-align: right;
      }

      h6 {
        font-size: 18px;
        color: var(--colorBrand);
        font-weight: var(--fontWeightRegular);
        margin-bottom: 20px;
        padding: 0;
      }

      h3 {
        font-weight: var(--fontWeightBold);
        font-size: 24px;
        margin: 5px 0 20px 0;
        padding: 0;
        @media (--viewportSmall) {     
          font-size: 36px;
        }
        @media (--viewportMedium) {
          font-size: 48px;
        }
      }

      p {
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }
  }
}