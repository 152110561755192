@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.service {
  padding: 30px 15px;
  width: 100%;

  @media (--viewportSmall) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 550px) {
    .container {
      margin: 0;
      max-width: none;
    }
  }

  .marquee {
    overflow: hidden;
    white-space: nowrap;
    width: calc(100vw - 50px);

    span {
      font-size: 84px;
      font-weight: var(--fontWeightBold);
      text-transform: uppercase;
      color: transparent;
      -webkit-text-stroke: 1px #f0f0f0;
      line-height: 1em;
      display: inline-block;
      margin: 0 30px;
    }
  }

  h3 {
    padding: 0;
    text-align: center;
    line-height: 1em;
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 100;
    text-transform: uppercase;

    @media (--viewportSmall) {
      font-size: 36px;
      padding: 0 20px;
      text-transform: none;
    }

    @media (--viewportLarge) {
      text-align: left;
    }

    span {
      font-weight: var(--fontWeightSemiBold);
    }
  }

  .innerContainer {
    width: 100%;

    @media (--viewportLarge) {
      /*display: flex;*/
    }

    .slider {
      display: none;

      @media (--viewportMedium) {
        display: block;
      }
    }

    .eventCardMobile {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      @media (--viewportMedium) {
        display: none;
      }
    }

    .eventCardLink {
      background: var(--colorPurple);
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 30px;
      margin-bottom: 15px;

      a {
        color: #ffffff;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    .rightContainer {
      display: flex;
      align-items: center;
      padding: 15px;

      @media (--viewportLarge) {
        width: 15%;
      }

      .footer {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: center;

        @media (--viewportLarge) {
          justify-content: space-between;
        }

        a {
          font-size: 12px;
          font-weight: var(--fontWeightSemiBold);
          color: #000;
          text-decoration: underline;
          line-height: 1.3em;
        }
      }
    }
  }

}

.dFlex {
  composes: dFlex from global;
  margin-top: -40px;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    color: var(--colorDarkGrey);
    text-decoration: underline;
  }
}

.howDifferent {
  display: block;
  background-color: #ffffff;
  margin-top: 30px;
  border-top: solid 1px var(--colorLight);
  border-bottom: solid 1px var(--colorLight);

  @media (--viewportLarge) {
    display: flex;
    margin-top: 100px;
  }

  div {
    padding: 20px;
    flex: 1;
    border-left: solid 1px var(--colorLight);
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (--viewportLarge) {
      padding: 50px 30px;
      gap: 20px;
    }
  }

  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: none;
  }

  div:nth-child(2) {
    background-color: #e953820a;
  }

  div:nth-child(3) {
    background-color: #e953821c;
  }

  div:nth-child(4) {
    background-color: #e953822e;
  }

  h4 {
    font-size: 16px;
    line-height: normal;

    @media (--viewportLarge) {
      font-size: 24px;
      margin: 0;
    }
  }

  h5 {
    font-size: 20px;
    line-height: normal;
    text-align: center;
    font-weight: var(--fontWeightSemiBold);

    @media (--viewportLarge) {
      font-size: 30px;
      text-align: left;
    }
  }

  p {
    margin: 0;
    font-size: 14px;

    @media (--viewportLarge) {
      font-size: 16px;
    }
  }

  img {
    width: 30px;

    @media (--viewportSmall) {
      width: 50px;
    }
  }
}