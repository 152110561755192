@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.hero {
  .backgroundImage {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media (--viewportMedium) {}

}

.heroSliderBlock {
  @media (--viewportLarge) {
    /* min-height: 74vh; */
  }
}

.HeroContainer {
  width: 100vw;

  @media (--viewportMedium) {
    width: calc(100vw - 17px);
    min-height: inherit;
    position: relative;
  }

  @media (--viewportLarge) {}
}

.overlay {
  margin-top: -40px;
  position: relative;

  @media (--viewportSmall) {
    position: absolute;
    width: 100%;
    bottom: -45px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttonStack {
    text-align: center;
    padding: 0 15px;
    position: relative;
    z-index: 2;

    @media (--viewportLarge) {
      margin-bottom: 30px;
      padding: 0;
    }

    @media (--viewportLargeWithPaddings) {
      padding: 0;
    }

    button {


      background: var(--bgBrandGradient);
      border-radius: 50px;
      color: var(--colorWhite);
      border: none;
      padding: 15px 30px;
      height: auto;
      font-weight: var(--fontWeightBold);
      font-size: 14px;
      cursor: pointer;
      box-shadow: 0px 25px 30px -15px rgb(233, 83, 130);
      transition: all .2s ease-in-out;
      margin: 5px;
      min-width: 200px;

      @media (--viewportSmall) {
        margin: 15px;
        font-weight: var(--fontWeightBold);
        font-size: 16px;
        outline: solid 7px;
        display: inline-block;
        width: auto;
        box-shadow: var(--boxShadowFilterButton);
      }

      @media (--viewportLarge) {
        min-width: 280px;
        padding: 25px 40px;
        font-size: 18px;
      }

      &.whiteButton {
        background: var(--colorBrand);
        color: #fff;

        @media (--viewportSmall) {
          color: #000;
          background: #fff;
          outline-color: rgba(255, 255, 255, 0.35);
        }
      }

      &.transparentButton {
        color: var(--colorBrand);
        background: rgba(255, 255, 255, 0.25);
        box-shadow: none;
        border: solid 1px var(--colorBrand);

        @media (--viewportSmall) {
          color: #fff;
          border: none;
          background: rgba(255, 255, 255, 0.25);
          backdrop-filter: saturate(180%) blur(20px);
          outline-color: #fff;
          text-shadow: 0px 1px 1px #000000a1;
        }
      }

      &:hover {
        box-shadow: var(--boxShadowPopup);
      }
    }


  }

}

.searchAffix {
  >div {
    z-index: 1;
    position: relative;
  }
}

.searchBox {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-bottom: solid 1px var(--colorLight);
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: row;
  }

  @media (--viewportLarge) {
    display: inline-flex;
    gap: 15px;
    box-shadow: var(--boxShadowSectionLocationHover);
    padding: 10px 10px 10px 45px;
    border-radius: 50px;
    max-height: 85px;
  }

  &>div {
    border-bottom: solid 1px #ddd;
    padding: 10px 0;
    width: 100%;

    @media (--viewportSmall) {
      border-bottom-color: #f8f8f8;
      padding: 0;
      width: auto;
    }

    &:last-child {
      border: none;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 14px;
    padding: 0 0 5px 0;
    margin-bottom: 0;
    text-transform: none;

    @media (--viewportSmall) {
      margin-bottom: 6px;
    }
  }

  .formControl {
    border: none;
    padding: 0;
    font-size: 14px;

    @media (--viewportSmall) {}
  }

  .formLocation {
    width: 100%;

    @media (--viewportSmall) {
      width: 170px;
    }

    .formControl {
      padding-left: 20px;
      background: url(img/current.png) no-repeat left center;
    }
  }
}



.primaryButton {
  background: var(--bgBrandGradient);
  border-radius: 50px;
  color: var(--colorWhite);
  border: none;
  padding: 15px 30px;
  height: auto;
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (--viewportLarge) {
    padding: 20px 30px;
    font-size: 16px;
  }

  &:hover {
    background: var(--colorBrand);
    box-shadow: var(--boxShadowLight);
  }

  img {
    vertical-align: top;
    margin-right: 10px;
  }
}

.contentx {
  display: none;
}


.mobileContainer {
  h2 {
    text-transform: uppercase;
    width: 90%;
    margin-bottom: 30px;
    font-size: 36px;
    line-height: normal;
    font-weight: var(--fontWeightBlack);
    color: var(--colorDarkGrey);
  }

}
.mobileHero{
  position: relative;
  text-align: center;
  img{
    border-radius: 10px;
    max-width: 100%;
  }
  
}

.primaryButtonMobile {
  composes: buttonPrimary from global;
  width: auto;
  padding: 15px 30px;
  position: relative;
  top: -28px;
  display: flex;
  align-items: center;
  margin: auto;

  .btnIcon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.mobileSearch {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  margin: 15px 0;
  position: relative;

  .keywordSearch {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    box-shadow: var(--boxShadowSectionLocationHover);
    border-radius: 4px;
    flex: 1;
  }

  .formControl {
    border: none;
  }

  img {
    width: 30px;
  }

  .locationBtn {
    border: none;
    padding: 0;
    cursor: pointer;
    background: var(--colorDarkGrey);
    padding: 14.35px;
    border-radius: 4px;

    img {
      width: 25px;
    }
  }
}

.locationInput {
  background-color: #fff;
  box-shadow: var(--boxShadowSectionLocationHover);
  border-radius: 4px;
  padding: 10px;
  position: relative;

  &:after {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent #FFFFFF transparent;
    transform: rotate(0deg);
    position: absolute;
    top: -8px;
    right: 10px;
    content: "";
  }
}